<template>
    <div class="page-rooter-fff">
        <nav-bar v-if="appTypeStr !== 'mini'" headerTitle="来店指引" backColor="#ffffff" :showBack="true" :header-back="headerBack" :showShare="true" @right-txt-click="shareBtn"/>
        <app-page class="page-img">
            <div class="top">
                <img
                    src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide1.png"
                    alt=""/>
                <div class="venue-name">
                    <div>{{ guideInfoType0.veuneName }}</div>
                </div>
                <div class="address" @click="openLocation()">
                    <div class="text1 ellipsis">{{ guideInfoType0.venueAddress }}</div>
                    <div class="text2" v-if="guideInfoType0.venueDistance">{{ guideInfoType0.venueDistance }}</div>
                </div>
            </div>
            <div class="nav-box">
                <div v-if="guideInfoType0.veuneGuideInfoImg || guideInfoType0.guideLocationImgList.length" @click="getVenueGuideInfo('0')" :class="type==='0'?'active':''">门店位置</div>
                <div v-if="guideInfoType1.veuneGuideInfoImg || guideInfoType1.guideParkingLotVoList.length" @click="getVenueGuideInfo('1')" :class="type==='1'?'active':''">停车场</div>
                <div v-if="guideInfoType2.veuneGuideInfoImg || guideInfoType2.venueGuideTransitVoList.length || guideInfoType2.veuneGuideInfoImgSpecial || guideInfoType2.venueGuideMetroVoList.length" @click="getVenueGuideInfo('2')" :class="type==='2'?'active':''">公共交通</div>
            </div>
            <div class="venue-title" v-show="type==='0'">
                <div class="text1">{{ guideInfoType0.veuneGuideInfoTitle }}</div>
                <div class="text2">{{ guideInfoType0.veuneGuideInfoText }}</div>
            </div>
            <div class="venue-title" v-show="type==='1'">
                <div class="text1">{{ guideInfoType1.veuneGuideInfoTitle }}</div>
                <div class="text2">{{ guideInfoType1.veuneGuideInfoText }}</div>
            </div>
            <div class="venue-title" v-show="type==='2'">
                <div class="text1">{{ guideInfoType2.veuneGuideInfoTitle }}</div>
                <div class="text2">{{ guideInfoType2.veuneGuideInfoText }}</div>
            </div>
            <div class="pic-box transport-box" v-show="type==='0'">
                <img v-if="guideInfoType0.veuneGuideInfoImg" :src="guideInfoType0.veuneGuideInfoImg">
                <div class="line-title-box" v-for="(item, index) in guideInfoType0.guideLocationImgList" :key="index">
                    <div v-if="item">线路{{index+1}}</div>
                    <img v-if="item" :src="item">
                </div>

            </div>
            <div v-show="type==='1'">
                <div class="pic-box transport-box">
                    <img v-if="guideInfoType1.veuneGuideInfoImg" :src="guideInfoType1.veuneGuideInfoImg">
                    <div v-if="guideInfoType1.guideParkingLotVoList.length">
                        <div class="bus-box" v-for="(item, index) in guideInfoType1.guideParkingLotVoList"
                             :key="index">
                            <div class="bus-name ellipsis">{{ item.titleText }}</div>
                            <div class="bus-desc ellipsis">{{item.userDistance}}{{ (item.userDistance&&item.address)?" | ":"" }}{{ item.address }}</div>
                            <div class="desc-list bus">
                                <div v-if="item.walkingTime">
                                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide8.png" alt="">
                                    <div>步行 {{ item.walkingTime }}</div>
                                </div>

                                <div v-if="item.distanceVenue">
                                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide9.png" alt="">
                                    <div>距离门店 {{ item.distanceVenue }}</div>
                                </div>
                            </div>
                            <div class="park-address">{{ item.price }}</div>
                            <div v-if="item.lat && item.lng" class="sort-box"  @click="openParkingLocation(item)">
                                <div class="sort1">
                                    <div><span>P</span>{{ index + 1 }}</div>
                                </div>
                                <div class="parking">去停车 <van-icon color="#267DFF" name="arrow" /></div>
                            </div>
                            <div v-else class="sort-box"  @click="openParkingLocation(item)">
                                <div class="sort1">
                                    <div><span>P</span>{{ index + 1 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="type==='2'">
                <div class="pic-box transport-box">
                    <img v-if="guideInfoType2.veuneGuideInfoImg" :src="guideInfoType2.veuneGuideInfoImg">
                    <div v-if="guideInfoType2.venueGuideTransitVoList.length">
                        <div class="type-title">公交</div>
                        <div class="bus-box bus-station" v-for="(item, index) in guideInfoType2.venueGuideTransitVoList"
                             :key="index">
                            <div class="bus-name ellipsis">{{ item.titleText }}</div>
                            <div class="bus-desc ellipsis">{{ item.stopLine }}</div>
                            <div class="desc-list bus">
                                <div v-if="item.walkingTime">
                                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide8.png" alt="">
                                    <div>步行 {{ item.walkingTime }}</div>
                                </div>

                                <div v-if="item.distanceVenue">
                                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide9.png" alt="">
                                    <div>距离门店 {{ item.distanceVenue }}</div>
                                </div>
                            </div>
                            <div class="sort">
                                <div>{{ index + 1 }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pic-box transport-box">
                    <img v-if="guideInfoType2.veuneGuideInfoImgSpecial" :src="guideInfoType2.veuneGuideInfoImgSpecial">
                    <div v-if="guideInfoType2.venueGuideMetroVoList.length">
                        <div class="type-title">地铁</div>
                        <div class="bus-box" v-for="(item, index) in guideInfoType2.venueGuideMetroVoList" :key="index">
                            <div class="bus-name ellipsis">{{ item.titleText }}</div>
                            <div class="bus-desc ellipsis">{{ item.stopLine }}</div>
                            <div class="desc-list">
                                <div v-if="item.walkingTime">
                                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide8.png" alt="">
                                    <div>步行{{ item.walkingTime }}</div>
                                </div>

                                <div v-if="item.distanceVenue">
                                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide9.png" alt="">
                                    <div>距离门店{{ item.distanceVenue }}</div>
                                </div>

                                <div v-if="item.ridingTime">
                                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide5.png" alt="">
                                    <div>骑行{{ item.ridingTime }}</div>
                                </div>

                            </div>
                            <div class="sort">
                                <div>{{ index + 1 }}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </app-page>
    </div>
</template>

<script>
import wx from "weixin-js-sdk"
import appMixin from "@/mixin/appMixin";
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import {
    appGetLocal,
    appType,
    appBack,
    navMap
} from "../../lib/appMethod";
export default {
    mixins: [appMixin],
    components: {
        navBar,
        appPage,
    },
    data() {
        return {
            guideInfo: {
                venueGuideTransitVoList: [],
                venueGuideMetroVoList: [],
                guideParkingLotVoList: [],
                guideLocationImgList: []
            },
            guideInfoType0: {
                venueGuideTransitVoList: [],
                venueGuideMetroVoList: [],
                guideParkingLotVoList: [],
                guideLocationImgList: []
            },
            guideInfoType1: {
                venueGuideTransitVoList: [],
                venueGuideMetroVoList: [],
                guideParkingLotVoList: [],
                guideLocationImgList: []
            },
            guideInfoType2: {
                venueGuideTransitVoList: [],
                venueGuideMetroVoList: [],
                guideParkingLotVoList: [],
                guideLocationImgList: []
            },
            type: '0',
            latAndlng: [],
            appTypeStr:'',
            shareObj: {
                type: 'share',
                shareImage: '',
                shareTitle: '',
                shareUrl: '',
            }
        };
    },
    created() {
        this.appTypeStr = appType()
        this.type = this.$route.query.type ?? '0'
        this.venueId = this.$route.query.venueId ?? ''
    },
    mounted() {
        this.load();
    },
    methods: {
        // 页面初次加载
        async load() {
            if (this.appTypeStr === 'ios' || this.appTypeStr === 'and') {
                try {
                    this.appCommParams = await this.getAppParams2([
                        appGetLocal()
                    ]);
                    this.latAndlng = this.appCommParams[0].replace('lat-', '').replace('long-', '').replace(RegExp("Failed", "g"), '').split('|')
                    console.log(this.latAndlng)
                    this.lat = this.latAndlng[0] ?? ""
                    this.lng = this.latAndlng[1] ?? ""
                    this.getAllInfo()
                } catch (err) {
                    this.lat = this.latAndlng[0] ?? "26.22"
                    this.lng = this.latAndlng[1] ?? "119.63"
                    this.getAllInfo()
                }
            } else if (this.appTypeStr === 'mini') {
                this.lat = this.$route.query.latitude ?? ''
                this.lng = this.$route.query.longitude ?? ''
                this.getAllInfo()
            }
        },
        getVenueGuideInfo(type) {


            this.type = type

            /*if(!this.venueId){
                this.$toast({
                    message: '参数错误！'
                })
                return false
            }
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            this.$axios.post(this.baseURLApp + '/newVenue/getVenueGuideInfo', {
                "lat": this.lat,
                "lng": this.lng,
                "type": type,
                "venueId": this.venueId
            }).then(res => {
                console.log(res)
                if (res.code === '1') {
                    this.type = type
                    this.$toast.clear()
                    let dataObj = res.data
                    dataObj.venueGuideTransitVoList = dataObj.venueGuideTransitVoList ?? []
                    dataObj.venueGuideMetroVoList = dataObj.venueGuideMetroVoList ?? []
                    dataObj.guideParkingLotVoList = dataObj.guideParkingLotVoList ?? []
                    this.guideInfo = dataObj


                }
            })*/
        },
        getAllInfo(){
            let that = this
            that.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            Promise.all([
                that.getType0(),
                that.getType1(),
                that.getType2()
            ]).then(res=>{
                that.$toast.clear()
                console.log(that.type)
                let guideInfoType0 = that.guideInfoType0,
                    guideInfoType1 = that.guideInfoType1,
                    guideInfoType2 = that.guideInfoType2;
                if(that.type === '0'){
                    if(!guideInfoType0.veuneGuideInfoImg && !guideInfoType0.guideLocationImgList.length){
                        if(guideInfoType1.veuneGuideInfoImg || guideInfoType1.guideParkingLotVoList.length){
                            that.type = '1'
                        }else if(guideInfoType2.veuneGuideInfoImg || guideInfoType2.venueGuideTransitVoList.length || guideInfoType2.veuneGuideInfoImgSpecial || guideInfoType2.venueGuideMetroVoList.length){
                            that.type = '2'
                        }
                    }
                }else if(that.type === '1'){
                    if(!guideInfoType1.veuneGuideInfoImg && !guideInfoType1.guideParkingLotVoList.length){
                        if(guideInfoType0.veuneGuideInfoImg || guideInfoType0.guideLocationImgList.length){
                            that.type = '0'
                        }else if(guideInfoType2.veuneGuideInfoImg || guideInfoType2.venueGuideTransitVoList.length || guideInfoType2.veuneGuideInfoImgSpecial || guideInfoType2.venueGuideMetroVoList.length){
                            that.type = '2'
                        }
                    }
                }else if(that.type === '2'){
                    if(!guideInfoType2.veuneGuideInfoImg && !guideInfoType2.venueGuideTransitVoList.length && !guideInfoType2.veuneGuideInfoImgSpecial && !guideInfoType2.venueGuideMetroVoList.length){
                        if(guideInfoType0.veuneGuideInfoImg || guideInfoType0.guideLocationImgList.length){
                            that.type = '0'
                        }else if(guideInfoType1.veuneGuideInfoImg || guideInfoType1.guideParkingLotVoList.length){
                            that.type = '1'
                        }
                    }
                }
            })
        },
        getType0() {
            return new Promise(resolve => {
                this.$axios.post(this.baseURLApp + '/newVenue/getVenueGuideInfo', {
                    "lat": this.lat,
                    "lng": this.lng,
                    "type": "0",
                    "venueId": this.venueId
                }).then(res => {
                    resolve()
                    if (res.code === '1') {
                        this.guideInfoType0 = res.data
                        this.shareObj.shareUrl = '/pages/webView/index?webUrl='+window.location.origin+'/#/venue-guide&location=1&venueId='+this.venueId + "&type=" + this.type
                        this.shareObj.shareImage = 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/venueList/img_share_venue.png'
                        this.shareObj.shareTitle = `「${res.data.veuneName}」来店指引`


                        this.guideInfo = res.data
                        wx.miniProgram.postMessage({
                            data: this.shareObj
                        });

                    }
                })
            })

        },
        getType1() {
            return new Promise(resolve => {
                this.$axios.post(this.baseURLApp + '/newVenue/getVenueGuideInfo', {
                    "lat": this.lat,
                    "lng": this.lng,
                    "type": "1",
                    "venueId": this.venueId
                }).then(res => {
                    resolve()
                    if (res.code === '1') {
                        this.guideInfoType1 = res.data
                    }
                })
            })

        },
        getType2() {
            return new Promise(resolve => {
                this.$axios.post(this.baseURLApp + '/newVenue/getVenueGuideInfo', {
                    "lat": this.lat,
                    "lng": this.lng,
                    "type": "2",
                    "venueId": this.venueId
                }).then(res => {
                    resolve()
                    if (res.code === '1') {
                        this.guideInfoType2 = res.data
                    }
                })
            })
        },

        async getAppParams2(promiseArr) {
            return await Promise.all(promiseArr)
        },
        // 返回键
        headerBack() {
            if (window.history.length > 1) {
                this.$router.back(-1);
            } else {
                appBack()
            }
        },
        openLocation() {
            let that = this
            if(!that.guideInfo.venueLat || !that.guideInfo.venueLng){
                this.$toast({
                    message:'无定位',
                    duration:1000
                })
                return false
            }
            try {
                let data = {
                    lat: parseFloat(that.guideInfo.venueLat),
                    lng: parseFloat(that.guideInfo.venueLng),
                    name: that.guideInfo.veuneName
                }
                if(this.appTypeStr === 'mini'){
                    data = Object.assign(data,{address: that.guideInfo.venueAddress})
                }
                navMap(data)
            }catch (e){
                console.log(e)
            }
        },
        openParkingLocation(item) {
            let that = this
            if(!item.lat || !item.lng){
                this.$toast({
                    message:'无定位',
                    duration:1000
                })
                return false
            }
            try {
                let data = {
                    lat: parseFloat(item.lat),
                    lng: parseFloat(item.lng),
                    name: item.titleText
                }
                if(this.appTypeStr === 'mini'){
                    data = Object.assign(data,{address: item.address})
                }
                navMap(data)
            }catch (e){
                console.log(e)
            }
        },
        shareBtn(){
            if( !this.shareObj.shareTitle ||  !this.shareObj.shareUrl ||  !this.shareObj.shareImage){
                return false
            }
            let shareObj = this.shareObj
            if (this.appTypeStr === 'ios' || this.appTypeStr === 'and'){
                let params = {}
                let shareParam = {
                    title: shareObj.shareTitle,
                    image: shareObj.shareImage,
                    path: shareObj.shareUrl,
                    miniProgramType: "0", // 正式0，开发1，体验2
                }
                shareParam = Object.assign(shareParam, this.h5ShareObj)
                params.type = "3";
                try {
                    shareParam.miniProgramType = this.isTestEnvironment ? '2' : '0'
                } catch (e) {
                    console.log('e', e)
                }
                params.params = Object.assign({}, shareParam, {
                    url: "", //必填，不然报错
                    multiple: "0.95",
                    userName: "gh_7f5b57b6a76e",
                    scene: "0", // 0 好友 1朋友圈
                });
                try {
                    if (this.appTypeStr == "and") {
                        App.onShareWeCharAndWeZoom(JSON.stringify(params))
                    } else if (this.appTypeStr == "ios") {
                        window.webkit.messageHandlers.wechatShare.postMessage(params);
                    }
                } catch (e) {
                    console.log('share err', e)
                }
            }

        },
    }
};
</script>
<style lang="less" scoped>
.page-rooter-fff{
    min-height: 100vh;
}
.page-img {
    background: #F9FAFB;

    .top {
        position: relative;
        height: 483px;
        img {
            width: 100%;
            height: auto;
        }

        .venue-name {
            position: absolute;
            width: 100%;
            bottom: 224px;
            left: 0;
            height: 32px;
            font-size: 32px;
            font-weight: bold;
            color: #FFE6B5;
            line-height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide2.png") left center no-repeat,
                url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide3.png") right center no-repeat;
                background-size: 24px 24px;
                padding: 0 24px;
            }
        }

        .address {
            position: absolute;
            left: 32px;
            bottom: 64px;
            width: 686px;
            height: 112px;
            background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/shutterstock.png") no-repeat center center;
            background-size: 100%;

            .text1 {
                width: 490px;
                height: 32px;
                font-size: 24px;
                font-weight: 400;
                color: #F9FAFB;
                line-height: 32px;
                margin: 20px 0 8px 56px;
            }

            .text2 {
                width: 490px;
                height: 32px;
                font-size: 24px;
                font-weight: 400;
                color: #9AA1A9;
                line-height: 32px;
                margin: 8px 0 0 56px;
            }
        }
    }

    .nav-box {
        display: flex;
        align-items: center;
        margin: 44px 0 76px;
        padding: 0 32px;

        div {
            width: 168px;
            height: 60px;
            background: #EEEEEE;
            border-radius: 6px;
            font-size: 24px;
            color: #242831;
            line-height: 60px;
            text-align: center;
            margin-right: 16px;

            &.active {
                background: #FFDE00;
            }
        }

    }

    .venue-title {
        .text1 {
            font-size: 44px;
            font-weight: bold;
            color: #242831;
            line-height: 48px;
            text-align: center;
        }

        .text2 {
            font-size: 24px;
            font-weight: 400;
            color: #6C727A;
            line-height: 28px;
            text-align: center;
            margin-top: 16px;
        }
    }

    .pic-box {
        width: 100%;
        padding: 0 32px;
        box-sizing: border-box;

        &.transport-box {
            padding-bottom: 72px;


        }
        .line-title-box{
            div{
                font-size: 44px;
                font-weight: 600;
                color: #3C454E;
                line-height: 48px;
                padding: 40px 0 24px;
                text-align: center;
            }
            img{
                width: 100%;
            }
        }

        & > img {
            margin-top: 20px;
            width: 100%;
        }

        .type-title {
            font-size: 44px;
            font-weight: bold;
            color: #242831;
            line-height: 48px;
            text-align: center;
            padding: 16px 0 0;
        }

        .bus-box {
            width: 686px;
            background: #FFFFFF;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.03);
            border-radius: 12px;
            padding: 32px 24px;
            margin-top: 40px;
            box-sizing: border-box;
            position: relative;
            .park-address{
                font-size: 22px;
                font-weight: 400;
                color: #6C727A;
                line-height: 32px;
                border-top: 2px solid #f5f5f5;
                padding-top: 10px;
                margin-top: 32px;
            }
            &.bus-station {
                .sort {
                    background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide4.png");
                }
            }

            .sort {
                position: absolute;
                right: 22px;
                top: -34px;
                width: 100px;
                height: 116px;
                background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide6.png") no-repeat center center;
                background-size: cover;
                padding-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                box-sizing: border-box;
                div {
                    color: #ffffff;
                    font-size: 28px;
                    font-family: BebasNeue;
                    font-weight: bold;
                    line-height: 33px;
                    padding-right: 4px;
                }
            }
            .sort-box{
                position: absolute;
                right: 22px;
                top: -34px;
                .parking{
                    font-size: 22px;
                    font-weight: 400;
                    color: #267DFF;
                    line-height: 22px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .sort1{
                align-items: center;
                padding-bottom: 12px;
                padding-top: 12px;
                width: 100px;
                height: 116px;
                background: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/venue-guide/venue-guide7.png") no-repeat center center;
                background-size: cover;
                display: flex;
                justify-content: center;
                box-sizing: border-box;
                div{
                    padding-right: 0;
                    color: #ffffff;
                    font-size: 28px;
                    font-family: BebasNeue;
                    font-weight: bold;
                    line-height: 33px;
                    span{
                        font-size: 56px;
                        font-weight: normal;
                    }
                }
            }
            .bus-name {
                font-size: 32px;
                font-weight: bold;
                color: #242831;
                line-height: 36px;
                width: calc(100% - 110px);
            }

            .bus-desc {
                font-size: 24px;
                font-weight: 400;
                color: #9AA1A9;
                line-height: 24px;
                margin-top: 16px;
                width: calc(100% - 120px);
            }

            .desc-list {
                display: flex;
                align-items: center;
                margin: 40px 0 10px;

                div:not(:last-child) {
                    margin-right: 36px;
                }

                &.bus {
                    & > div {
                        font-size: 28px;
                        line-height: 48px;
                        img {
                            width: 48px;
                            height: 48px;
                        }
                    }

                    & > div:not(:last-child) {
                        margin-right: 70px;
                    }
                }

                & > div {
                    display: flex;
                    align-content: center;
                    font-size: 22px;
                    font-weight: 400;
                    color: #3C454E;
                    line-height: 42px;
                    img {
                        width: 40px;
                        height: 40px;
                        margin-right: 12px;
                    }
                }

            }
        }
    }
}

</style>

